import React from "react"
// Components

// Styles
import styles from "../pages/scss/products.module.scss"

const category = props => (
  <a
    className={styles.categoryImage}
    style={{
      backgroundImage: `url(${props.image.fixed.src})`,
      height: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
    onClick={() => {
      props.handleCategoryChange(props.categoryName)
    }}
    tabIndex="0"
    href="#search"
  >
    <h3 id={props.categoryName === "Frozen" ? "search" : ""}>
      {props.displayName}
    </h3>
  </a>
)

export default category
