import React from "react"
import { StaticQuery, graphql } from "gatsby"
// Components
import Category from "./category"
// Styles
import styles from "../pages/scss/products.module.scss"

export default props => (
  <StaticQuery
    query={graphql`
      {
        contentfulProductsSection {
          fruitImage {
            title
            description
            fixed(width: 500) {
              src
            }
          }
          vegetableImage {
            title
            description
            fixed(width: 500) {
              src
            }
          }
          dairyImage {
            title
            description
            fixed(width: 500) {
              src
            }
          }
          frozenImage {
            title
            description
            fixed(width: 500) {
              src
            }
          }
          otherImage {
            title
            description
            fixed(width: 500) {
              src
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styles.categories}>
        <Category
          image={data.contentfulProductsSection.fruitImage}
          categoryName="Fruit"
          displayName="Fruit"
          handleCategoryChange={props.handleCategoryChange}
        />
        <Category
          image={data.contentfulProductsSection.vegetableImage}
          categoryName="Vegetables"
          displayName="Vegetables"
          handleCategoryChange={props.handleCategoryChange}
        />
        <Category
          image={data.contentfulProductsSection.dairyImage}
          categoryName="Dairy"
          displayName="Dairy"
          handleCategoryChange={props.handleCategoryChange}
        />
        <Category
          image={data.contentfulProductsSection.frozenImage}
          categoryName="Frozen"
          displayName="Frozen"
          handleCategoryChange={props.handleCategoryChange}
        />

        <Category
          image={data.contentfulProductsSection.otherImage}
          categoryName=""
          displayName="All"
          handleCategoryChange={props.handleCategoryChange}
        />
      </div>
    )}
  />
)
