import React from "react"
import { Link } from "gatsby"
import { slugify } from "../util/utilityFunctions"
import Fade from "react-reveal/Fade"

// Components
import Categories from "./categories"
// Styles
import styles from "../pages/scss/products.module.scss"

const ProductsList = props => {
  props.filteredProducts.sort(function(a, b) {
    let textA = a.subcategory.name.toUpperCase()
    let textB = b.subcategory.name.toUpperCase()
    return textA.localeCompare(textB)
  })

  return (
    <>
      <Categories handleCategoryChange={props.handleCategoryChange} />

      <form
        className={styles.form}
        onSubmit={evt => {
          evt.preventDefault()
        }}
      >
        <input
          className={styles.searchBox}
          placeholder="Search..."
          type="text"
          value={props.value}
          onChange={props.handleChange}
        ></input>

        <select
          className={styles.sortBox}
          onBlur={props.handleSubCategoryChange}
        >
          {props.filteredSubCategories.map(category => {
            if (category === "") {
              return (
                <option key={category} disabled>
                  Sort By..
                </option>
              )
            } else return <option key={category}>{category}</option>
          })}
        </select>
      </form>
      <div className={styles.productAmountText}>
        <h3> {props.category === "" ? "All Items" : props.category} </h3>{" "}
        <span>{props.filteredProducts.length} Product(s)</span>
      </div>
      <ul>
        {props.filteredProducts.map((item, index) => {
          return (
            <Fade bottom duration={1500} key={index}>
              <li className={styles.productListItem} key={item.id}>
                <Link
                  to={`/products/${slugify(item.category.name)}/${slugify(
                    item.subcategory.name
                  )}/${slugify(item.name)}`}
                >
                  <span>{item.name}</span>

                  <span>
                    <i>{item.subcategory.name}</i>
                  </span>
                </Link>
              </li>
            </Fade>
          )
        })}
        {props.filteredProducts.length === 0 ? (
          <li>{props.noProductsText}</li>
        ) : (
          ""
        )}
      </ul>
    </>
  )
}

export default ProductsList
