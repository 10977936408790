import React, { Component } from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// Components
import Layout from "../components/layout"
import SEO from "../SEO"
import GA from "../components/ga"
import ProductsList from "../components/productsList"
import Hero from "../components/hero"
import Newsletter from "../components/newsLetter"
import Sidebar from "../components/sidebar"
// import SidebarCart from "../components/sidebarCart"

// Styles
import styles from "./scss/products.module.scss"

class Products extends Component {
  state = {
    products: this.props.data.allContentfulProduct.edges,
    subCategories: this.props.data.allContentfulSubcategory.edges,
    categories: this.props.data.allContentfulCategory.edges,
    value: "",
    category: "",
    subcategory: "",
    filteredProducts: [],
    filteredSubCategories: [],
  }

  handleFilter = () => {
    const products = this.state.products
    let filteredSubCategories = this.state.subCategories
    var filteredProducts = []

    Object.keys(products).forEach((key) => {
      filteredProducts.push(products[key].node)
    })

    const value = this.state.value.toLowerCase() // shorten code below
    const category = this.state.category
    const subCat = this.state.subcategory

    filteredProducts = filteredProducts
      .filter((item) => {
        if (category === "") {
          // shouldnt be empty when category is passed down, but check for name/subCat anyway
          if (
            item.name.toLowerCase().indexOf(value) !== -1 ||
            item.subcategory.name.toLowerCase().indexOf(value) !== -1
          )
            return item
        } else {
          return item.category.name.indexOf(category) !== -1
        }
        return false
      })
      .filter((item) => {
        if (subCat === "All") {
          // show all items from category
          return item
        } else if (subCat !== "") {
          // if subcategory chosen, can search by name/subCat
          if (item.subcategory.name === subCat) {
            if (
              item.name.toLowerCase().indexOf(value) !== -1 ||
              item.subcategory.name.toLowerCase().indexOf(value) !== -1
            )
              return item
          }
        } else if (
          item.name.toLowerCase().indexOf(value) !== -1 ||
          item.subcategory.name.toLowerCase().indexOf(value) !== -1
        ) {
          // lastly, just search by name/subCat
          return item
        }
        return false
      })

    filteredSubCategories = [
      ...new Set(filteredProducts.map((item) => item.subcategory.name)),
    ]
    filteredSubCategories.unshift("All")
    filteredSubCategories.unshift("")

    this.setState({ filteredProducts, filteredSubCategories })
  }

  handleCategoryReset = () => {
    this.setState({ subcategory: "" }, () => {
      this.handleFilter()
    })
  }

  handleCategoryChange = (name) => {
    this.setState({ category: name, subcategory: "", value: "" }, () => {
      this.handleFilter()
    })
  }

  handleChange = (evt) => {
    this.setState({ value: evt.target.value }, () => {
      this.handleFilter()
    })
  }

  handleSubCategoryChange = (evt) => {
    this.setState({ subcategory: evt.target.value }, () => {
      this.handleFilter()
    })
  }

  componentDidMount() {
    const category = this.props.location.state

    if (category) {
      this.setState({ category: category.category }, () => {
        this.handleFilter()
      })
    } else {
      this.setState({ category: "" }, () => {
        this.handleFilter()
      })
    }
  }

  render() {
    const { props } = this

    return (
      <>
        <SEO title="Brophy Brothers | Products" />
        <GA />
        <Layout>
          {/* <SidebarCart /> */}
          <Hero
            text=""
            image={props.data.contentfulProductsPageHero.heroImage.file.url}
            buttons={false}
          />
          <section className={`section ${styles.products}`}>
            <div className={styles.container}>
              <div className="banner">
                <h2 className="ribbon">
                  <span className="top">&nbsp;</span>
                  <span className="text">
                    {props.data.contentfulProductsPageSection.title}
                  </span>
                  <span className="bottom">&nbsp;</span>
                </h2>
              </div>

              {documentToReactComponents(
                props.data.contentfulProductsPageSection.text.json
              )}
              <ProductsList
                category={this.state.category}
                handleCategoryChange={this.handleCategoryChange}
                value={this.state.value}
                handleChange={this.handleChange}
                handleSubCategoryChange={this.handleSubCategoryChange}
                filteredSubCategories={this.state.filteredSubCategories}
                filteredProducts={this.state.filteredProducts}
                noProductsText={
                  props.data.contentfulProductsPageSection.noProductsText
                }
              />
            </div>
          </section>
          <Newsletter />
          <Sidebar />
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    allContentfulProduct {
      totalCount
      edges {
        node {
          id
          name
          category {
            name
          }
          subcategory {
            name
          }
        }
      }
    }
    contentfulProductsPageSection {
      title
      text {
        json
      }
      noProductsText
    }
    allContentfulCategory {
      edges {
        node {
          name
          id
        }
      }
    }
    allContentfulSubcategory {
      edges {
        node {
          name
          id
        }
      }
    }

    contentfulProductsPageHero {
      text
      heroImage {
        file {
          url
        }
      }
    }
  }
`

export default Products

// props is defined but never used..
// eslint-disable-next-line
// const props = () => (
//   <StaticQuery
//     query={graphql`
//      {
//         allContentfulProduct {
//           totalCount
//           edges {
//             node {
//               id
//               name
//               category {
//                 name
//               }
//               subcategory {
//                 name
//               }
//             }
//           }
//         }
//         contentfulProductsPageSection {
//           title
//           text {
//             json
//           }
//           noProductsText
//         }
//         allContentfulCategory {
//           edges {
//             node {
//               name
//               id
//             }
//           }
//         }
//         allContentfulSubcategory {
//           edges {
//             node {
//               name
//               id
//             }
//           }
//         }

//         contentfulProductsPageHero {
//           text
//           heroImage {
//             file {
//               url
//             }
//           }
//         }
//       }
//     `}
//     render={data => <Products data={data} {...props} /> }
//   />
// )
